import "./gallery"
import "./main.js"
import "../scss/main.scss"

window.galleries = {
    "between" : require('./galleries/between'),
    "dondevas" : require('./galleries/dondevas'),
    "zdrowie" : require('./galleries/zdrowie'),
    "animals" : require('./galleries/animals'),
    "landscape" : require('./galleries/landscape'),
    "else" : require('./galleries/else.json')


};




